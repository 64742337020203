import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
const layoutClassName = "max-w-3xl mx-auto px-6";

export function PreviousLink({children, ...props}){
  return <div className={classnames(layoutClassName, "text-lg text-[#3B487F] font-medium  hover:opacity-50")} {...props}>
            {children}
          </div>
}

export function Title(props){
  return <p className={classnames(layoutClassName, "text-5xl font-bold font-serif leading-[1.14]")} {...props}/>
}

export function Author(props){
  return <p className={classnames(layoutClassName, "text-slate-600 mt-2 text-lg ")} {...props}/>
}

export function Ul(props){
  return <div className={classnames(layoutClassName)} {...props}/>
}

export function Li(props){
  return  <li className="my-2 ml-2 text-lg text-gray-700 leading-[1.6] list-none ml-6 ">
            <span><p className="bg-[#3B487F] h-2 w-2 rounded-full mt-2 -ml-6 mr-3 inline-flex "/></span>
            <span>{props.children}</span>
          </li>
}

export function Summary(props){
  return <div>
          <div className={classnames(layoutClassName, "text-[130%] text-slate-400 leading-[1.6] mt-6")} {...props}/>
          <div className="border-t-2 border-gray-200 w-24 mx-auto my-12"/>
        </div>
}

export function Citation({children, author, role}){
  return <div className={classnames(layoutClassName, "max-w-2xl text-center")}>
          <div className="border-t-2 border-gray-200 w-24 mx-auto mb-6"/>
          <p className="text-xl text-[#566BC4] text-center">"{children}"</p>
          {author && <p className="text-xl mt-4 ">{author}</p>}
          {role && <p className="text-slate-500">{role}</p>}
          <div className="border-t-2 border-gray-200 w-24 mx-auto mt-6"/>
         </div>
}

export function Paragraph(props){
  return <p className={classnames(layoutClassName, "text-lg text-gray-700 leading-[1.6] ")} {...props}/>
}

export function H1(props){
  return <h1 className={classnames(layoutClassName, "text-3xl text-gray-700 leading-[1.6] ")} {...props}/>
}

export function H2(props){
  return <h2 className={classnames(layoutClassName, "text-3xl font-serif font-bold text-gray-700 leading-[1.6] ")} {...props}/>
}

export function H3(props){
  return <h3 className={classnames(layoutClassName, "text-xl mt-3 font-medium text-gray-700 ")} {...props}/>
}

export function H4(props){
  return <h4 className={classnames(layoutClassName, "text-lg text-[#566BC4] p-0 m-0 leading-[1.6] mt-3")} {...props}/>
}

export function Href(props){
  return <a className={classnames("text-[#566BC4] underline hover:opacity-80")} {...props}/>
}

export function Figure({size, ...props}){
  return <div className={classnames(layoutClassName, size==="md"? "max-w-md": size==="xl"?"max-w-xl": "")} {...props}/>
}

export function Caption(props){
  return <p className="text-base px-8 text-slate-500" {...props}/>
}

/*
  Meta at end of post
*/
export function Meta(props){
  return <div className={classnames(layoutClassName, "divide-y text-slate-400 pt-8 pb-20")} {...props}/>
}

export function MetaField({title, ...props}){
  const { t } = useTranslation("case-study");
  return <div className={classnames("grid grid-cols-4 py-3")}>
          <div className="col-span-1">{t(`meta.${title}`)}</div>
          <div className="col-span-3">{props.children}</div>
         </div>
}
