import GATSBY_COMPILED_MDX from "C:/Users/jging/Documents/hectiq/website/hectiq.ai/src/data/careers/fr/full-stack-dev.mdx";
import * as React from "react";
import {graphql, Link} from 'gatsby';
import {useTranslation} from 'react-i18next';
import {Page} from 'components/core/layouts/base';
import {EmptyHero} from 'components/case-study/hero';
import {Paragraph, H1, H2, H3, H4, Figure, Caption, Meta, MetaField, Href, Summary, Li, PreviousLink, Ul, Title, Citation} from 'components/case-study/typo';
import {MDXProvider} from "@mdx-js/react";
import {Footer} from 'components/core/footer';
import {WhyChooseUse} from 'components/careers/why';
import {FiArrowLeft} from 'react-icons/fi';
import {ConcaveCurve} from 'components/core/curves';
const shortcodes = {
  Link,
  Figure,
  Caption,
  Citation,
  Summary,
  Title,
  p: Paragraph,
  li: Li,
  ul: Ul,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  MetaField,
  Meta,
  a: Href
};
function CareerPage({data: {mdx}, location, children}) {
  const {t} = useTranslation("career");
  return React.createElement(Page, {
    dark: false,
    location: location,
    description: mdx.frontmatter.title
  }, React.createElement(EmptyHero), React.createElement(PreviousLink, null, React.createElement(Link, {
    to: "/careers",
    className: "flex items-center"
  }, React.createElement("span", null, React.createElement(FiArrowLeft, {
    className: "mr-2"
  })), "  ", React.createElement("span", null, t("nav")))), React.createElement(Title, null, mdx.frontmatter.title), React.createElement("div", {
    className: "pb-36 bg-blur-pattern"
  }, React.createElement("div", {
    className: "space-y-8"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children))), React.createElement("div", {
    className: "bg-slate-100 relative mt-12 "
  }, React.createElement(WhyChooseUse), React.createElement("div", {
    className: "relative h-36 "
  }, React.createElement(ConcaveCurve, {
    color: "text-[#354178]"
  }))), React.createElement(Footer));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CareerPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query ($id: String, $language: String!) {
    mdx(id: { eq: $id }) {
      id,
      frontmatter {
        title,
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
