import React from 'react';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import { ConcaveCurve } from 'components/core/curves';
import { Sphere } from 'components/core/sphere';

export function Hero(){
  const {t} = useTranslation("service");
  return  <div className="bg-[#354178] px-6 py-32 xl:pt-56 xl:pb-48 2xl:pb-72 flex items-center justify-center relative overflow-hidden">
            <div className="md:text-center  max-w-4xl ">
              <h1 className="font-bold text-white font-serif leading-[1.2] tracking-wide text-5xl md:text-6xl xl:text-7xl">{t("hero.welcome")}</h1>
              <p className="text-white opacity-[.75] mt-6 text-[20px] lg:text-[26px] max-w-3xl mx-auto leading-relaxed">{t("hero.subtitle")}</p>
            </div>
            <Sphere speed={-0.8} radius={250} offset={0} sphereId="hero-1" color="light-gray" className="max-h-20 hidden lg:block" svgClassName={"-translate-x-1/4"}/>
            <Sphere speed={0.5} radius={150} offset={0} sphereId="hero-2" color="light-gray" className="justify-start top-0 hidden lg:block" svgClassName={"-translate-x-[5rem] translate-y-[2em] lg:translate-y-[0em] lg:translate-x-[10rem] xl:translate-x-[25rem]"}/>
            <Sphere speed={-0.5} radius={150} offset={0} sphereId="hero-2" color="light-gray" className="justify-start top-0 lg:hidden" svgClassName={"-translate-x-[5rem] translate-y-[6em] lg:translate-y-[0em] lg:translate-x-[10rem] xl:translate-x-[18rem]"}/>
            <Sphere speed={0.15} radius={800} offset={0} sphereId="hero-3" color="light-gray" className="justify-end top-0" svgClassName={"scale-50 xl:scale-100 xl:translate-y-[12rem] translate-x-1/2 lg:translate-x-1/2"}/>
           <ConcaveCurve color="text-slate-50"/>
          </div>
}

export function Hero404(){
  const {t} = useTranslation("index");
  return  <div className="bg-[#354178] h-full px-6 py-32 xl:pt-56 xl:pb-48 2xl:pb-72 flex items-center justify-center relative">
            <div className="md:text-center  max-w-4xl ">
              <h1 className="font-bold text-white font-serif leading-[1.2] tracking-wide text-5xl md:text-6xl xl:text-7xl">{t("404.title")}</h1>
              <p className="text-white opacity-[.75] mt-6 text-[20px] lg:text-[26px] max-w-3xl mx-auto leading-relaxed">{t("404.description")}</p>
            </div>
            <Sphere speed={-0.8} radius={250} offset={0} sphereId="hero-1" color="light-gray" className="max-h-20 hidden lg:block" svgClassName={"-translate-x-1/4"}/>
            <Sphere speed={0.5} radius={150} offset={0} sphereId="hero-2" color="light-gray" className="justify-start top-0 hidden lg:block" svgClassName={"-translate-x-[5rem] translate-y-[2em] lg:translate-y-[0em] lg:translate-x-[10rem] xl:translate-x-[25rem]"}/>
            <Sphere speed={-0.5} radius={150} offset={0} sphereId="hero-2" color="light-gray" className="justify-start top-0 lg:hidden" svgClassName={"-translate-x-[5rem] translate-y-[6em] lg:translate-y-[0em] lg:translate-x-[10rem] xl:translate-x-[18rem]"}/>
            <Sphere speed={0.15} radius={800} offset={0} sphereId="hero-3" color="light-gray" className="justify-end top-0" svgClassName={"scale-50 xl:scale-100 xl:translate-y-[12rem] translate-x-1/2 lg:translate-x-1/2"}/>
          </div>
}

export function HeroSecondary({title}){
  return <div className="bg-slate-100 px-6 pb-32 pt-32 sm:py-32 md:pt-48 md:pb-56 flex items-center justify-center relative overflow-hidden">
          <div className="text-center max-w-4xl ">
            <h1 className="font-bold font-serif leading-[1.2] tracking-wide text-4xl sm:text-5xl md:text-7xl">{title}</h1>
          </div>
          <Sphere speed={-0.8} radius={250} offset={0} sphereId="hero-1" color="dark-blue" className="max-h-20 left-0 justify-start " svgClassName={""}/>
            <Sphere speed={0.15} radius={500} offset={0} sphereId="hero-3" color="dark-blue" className="justify-end top-0" svgClassName={"scale-75 xl:scale-100 xl:translate-y-[12rem] translate-x-3/4 lg:translate-x-1/2"}/>
          <ConcaveCurve color="text-white"/>
         </div>
}

export function HeroAsym({title, subtitle}){
  return <div className="bg-slate-100 px-6 pb-32 pt-32 sm:py-32 md:pt-48 md:pb-56 flex items-center justify-center relative overflow-hidden">
          <div className="text-left max-w-6xl w-full flex">
            <div className="max-w-2xl mr-auto">
              <h1 className="font-bold font-serif leading-[1.2] tracking-wide text-4xl sm:text-5xl">{title}</h1>
              <p className="opacity-[.75] mt-6 text-[20px] lg:text-[26px] mx-auto leading-relaxed">{subtitle}</p>
            </div>
          </div>
          <Sphere speed={-0.8} radius={250} offset={0} sphereId="hero-1" color="dark-blue" className="max-h-20 left-0 justify-start " svgClassName={""}/>
            <Sphere speed={0.15} radius={500} offset={0} sphereId="hero-3" color="dark-blue" className="justify-end top-0" svgClassName={"scale-75 xl:scale-100 xl:translate-y-[12rem] translate-x-3/4 lg:translate-x-1/2"}/>
          <ConcaveCurve color="text-white"/>
         </div>
}

export function EmptyHero(){
  return <div className="h-36 max-w-5xl mx-auto relative flex  items-start pb-36 md:pb-48">
            <Sphere speed={0.3} radius={170} offset={0} sphereId="empty-hero" color="gray-inverse" className="max-h-36 justify-end" svgClassName={""}/>
          </div>
}