import React from 'react';

export function SectionTitle({title}){
    return <h3 className="text-center font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{title}</h3>
}

export function SectionTitleLeft({title}){
    return <h3 className="font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{title}</h3>
}

export function SecondaryText({children}){
    return <p className=" text-slate-500 whitespace-pre-wrap my-4 leading-[1.4] tracking-wide text-lg lg:text-xl">{children}</p>
}

export function Li({children}){
    return <li className="text-slate-500 mb-2 leading-[1.4] tracking-wide text-lg lg:text-xl">{children}</li>
}

export const Typo = {
    SectionTitle,
    SectionTitleLeft,
    SecondaryText,
    Li
}