import React from 'react';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import { SectionTitle } from 'components/core/typo';

function Card({title, description}){
  return <div className=" rounded-md p-3 sm:p-6 flex flex-col ">
          <h1 className="text-xl font-medium">{title}</h1>
          <p className="mt-2 text-slate-600">{description}</p>
          </div>
}
export function WhyChooseUse(){
  const {t} = useTranslation("career");
  return <div className="mx-auto max-w-5xl px-6 py-24">
          <SectionTitle title={t("why.title")} />
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-3 lg:gap-6 my-8">
            <Card title={t("why.office.title")}
                  description={t("why.office.description")}/>
            <Card title={t("why.hybrid.title")}
                  description={t("why.hybrid.description")}/>
            <Card title={t("why.projects.title")}
                  description={t("why.projects.description")}/>
            <Card title={t("why.volunteer.title")}
                  description={t("why.volunteer.description")}/>
            <Card title={t("why.merch.title")}
                  description={t("why.merch.description")}/>
            <Card title={t("why.top-pay.title")}
                  description={t("why.top-pay.description")}/>
          </div>
          <p className="text-center mx-auto max-w-2xl text-lg text-slate-700">{t("why.more")}</p>
         </div>
}
